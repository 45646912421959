import React, { FunctionComponent, HTMLAttributes, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PAGE_LOAD_DELAY } from '../../../constants/general';
import { REVIEWS_SECTION_ID } from '../../../constants/product/general';
import { ProductStickyNavContext } from '../../../contexts/product-sticky-nav/product-sticky-nav.context';
import { AnalyticsEvent } from '../../../helpers/analytics/analytics.helper';
import { buildGTMReviewsBuySectionLinkClick } from '../../../helpers/analytics/gtm/gtm-utils.helper';
import { doesWindowExist, pluralize, scrollToElement } from '../../../helpers/general-helper/general-helper';
import { TextButton } from '../../buttons/text-button/text-button.component';
import { Link } from '../../common-components/link/link.component';

export type RatingProps = {
	// The rating to display, i.e. 4 out of 5 stars.
	ratingValue: number;
	// The number of reviews
	reviewCount?: number;
	// URL to the reviews
	url?: string;
	// If true, will show the review count in abbreviated form, i.e. (10) vs 10 Reviews
	abbreviatedReviewCount?: boolean;
	// if true, rating will provide no link or action
	readOnly?: boolean;
} & Pick<HTMLAttributes<HTMLElement>, 'className'>;

export type MaybeLinkProps = {
	className?: string;
	url?: string;
	onClick?: () => void;
	content: string;
	automationHook?: string;
	analyticsEvent?: AnalyticsEvent;
};

/**
 * The rating component could be used in an stylized anchor so when url and onClick are not present,
 * we need to render a div instead to prevent nesting anchor tags.
 */
const MaybeLink: FunctionComponent<MaybeLinkProps> = ({ className, url, onClick, content, automationHook, analyticsEvent }) => {
	if (url) {
		return (
			<Link
				className={`f6 ${className}`}
				underlineHover={true}
				url={url}
				automationHook={automationHook}
				analyticsEvent={analyticsEvent}>
				{content}
			</Link>
		);
	} else if (onClick) {
		return (
			<div className={`f6 ${className}`}>
				<TextButton underlineHover={true} onClick={onClick} automationHook={automationHook} analyticsEvent={analyticsEvent}>
					{content}
				</TextButton>
			</div>
		);
	}
	return <div className={`theme-primary no-underline f6 pl2 ${className}`}>{content}</div>;
};

type StarRatingProps = {
	ratingValue: number;
	className?: string;
};

export const StarRating: FunctionComponent<StarRatingProps> = ({ ratingValue, className }) => {
	return (
		<div className={`star-rated ${className}`}>
			<div className="star-rating" style={{ width: `${ratingValue}em` }} />
		</div>
	);
};

type StarRatingWithCountAndValueProps = {
	ratingValue: number;
	reviewCount: number;
};

export const StarRatingWithCountAndValue: FunctionComponent<StarRatingWithCountAndValueProps> = ({ ratingValue, reviewCount }) => (
	<div className="f6 theme-grey">
		<div className="flex">
			<StarRating ratingValue={ratingValue} className={'f3'} />
			<div className="pl1 pt1">({reviewCount})</div>
		</div>
		{reviewCount > 0 && <div className="pl1">{ratingValue} out of 5 Stars</div>}
	</div>
);

/**
 * Renders a star based rating and a link to the reviews.  Can show an optional link button to
 * write a new review.
 */
export const Rating: FunctionComponent<RatingProps> = ({
	className = '',
	ratingValue,
	reviewCount,
	url,
	abbreviatedReviewCount = false,
	readOnly = false
}) => {
	const [loading, setLoading] = useState<boolean>(true);
	const { hash } = useLocation();
	let timeout;
	const reviewsElement = doesWindowExist() && document.getElementById(REVIEWS_SECTION_ID);
	const { setSectionToOpen } = useContext(ProductStickyNavContext);

	const delayedScrollTo = () => {
		// Wait for the page to load before scrolling down to reviews
		timeout = setTimeout(() => {
			onReviewClick();
		}, PAGE_LOAD_DELAY);
	};

	// Checks if anchor tag #reviews exist in the url and scroll to 'reviews' when the page loads.
	useEffect(() => {
		if (reviewsElement && loading) {
			setLoading(false);
		}
		if (!loading && hash === '#reviews') {
			delayedScrollTo();
		}
		return () => {
			clearTimeout(timeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, reviewsElement, hash]);

	/**
	 * Read Reviews Click Handler
	 */
	const onReviewClick = () => {
		setSectionToOpen(REVIEWS_SECTION_ID);
		scrollToElement(REVIEWS_SECTION_ID, 75);
	};

	/**
	 * Handles Read Reviews link or Scroll To navigation methods
	 */
	const resolveReviewsLink = (): Partial<MaybeLinkProps> => {
		if (readOnly) {
			return {};
		}
		return url ? { url } : { onClick: onReviewClick };
	};

	const mobileReviewLinkCss = abbreviatedReviewCount ? 'db' : 'db dn-ns';
	const hasRating = ratingValue > 0;
	const identifier = `${ratingValue} out of 5 stars`;

	return (
		<div className={`flex flex-row items-center ${className}`} role="img" aria-label={identifier} data-testid={identifier}>
			{hasRating && (
				<>
					<StarRating ratingValue={ratingValue} />
					{reviewCount && !abbreviatedReviewCount && (
						<MaybeLink
							className={`pl2 dn db-ns`}
							content={`${reviewCount} ${pluralize('Review', reviewCount)}`}
							{...resolveReviewsLink()}
							analyticsEvent={{ data: buildGTMReviewsBuySectionLinkClick() }}
						/>
					)}
					{/* Mobile Link */}
					{reviewCount && (
						<MaybeLink
							className={`pl2 ${mobileReviewLinkCss}`}
							content={`(${reviewCount})`}
							{...resolveReviewsLink()}
							automationHook={'review-count'}
							analyticsEvent={{ data: buildGTMReviewsBuySectionLinkClick() }}
						/>
					)}
				</>
			)}
		</div>
	);
};
